export default {
  menu: {
    dashboard: 'Dashboard',
    events: 'Events',
    logout: 'Log out',
    manaNews: 'Mana News',
    reports: 'Reports',
    taskmanager: 'Task Manager',
    tiers: 'Ad & Deal Tiers',
    users: 'Users',
  },
  login: {
    authErrorMessage: 'Incorrect email or password',
    defaultErrorMessage:
      'We are experiencing an internal issue.\nPlease try again in a few minutes.',
    defaultErrorTitle: 'Something went wrong',
    didntReceiveTheCode: 'Didn’t receive the code?',
    email: 'Email',
    forgotPasswordTitle: 'Forgot password',
    login: 'Login',
    newPasswordTitle: 'New password',
    password: 'Password',
    title: 'Sign in',
    unpluggedErrorMessage: 'Please check your internet\nsettings and try again',
    unpluggedErrorTitle: 'No internet connection',
    updateFailed: 'Failed to update password',
    updateSuccessfully: 'Pasword updated successfully',
    verificationCode: 'Verification code',
  },
  global: {
    aM: 'am',
    apply: 'Apply',
    approve: 'Approve',
    asignOne: 'Please assign one',
    btnCreateNew: 'Create New',
    cancel: 'Cancel',
    community: 'Community',
    closed: 'Closed',
    comment: 'Comment',
    confirm: 'Confirm',
    delete: 'Delete',
    dismiss: 'Dismiss',
    dropFileRelease: 'Release to drop file here',
    dropFileSelect: 'or drop here',
    edit: 'Edit',
    filterReports: 'Filter reports',
    filterTasks: 'Filter tasks',
    filterTiers: 'Filter tiers',
    goBack: 'Go Back',
    international: 'International',
    labelFields: {
      email: 'Email',
      lastname: 'Lastname',
      name: 'Name',
      phoneOrEmail: 'Phone or email',
      role: 'Role',
      username: 'Username',
    },
    location: 'Location',
    main: 'Main',
    mainLocation: 'Main Location',
    manage: 'Manage',
    manaGlobal: 'Mana Global',
    manaInvestRole: 'Mana Invest',
    manaMemberRole: 'Mana Member',
    manaPartnerRole: 'Mana Partner',
    manaVendorRole: 'Mana Vendor',
    message: 'Message',
    myTasks: 'My tasks',
    nA: 'n/a',
    notFound: 'Not found',
    now: 'Now',
    pM: 'pm',
    post: 'Post',
    reject: 'Reject',
    rejectBusiness: 'Reject Business',
    requestEdits: 'Request edits',
    resend: 'Resend',
    retry: 'Retry',
    search: 'Search',
    select: 'Select',
    send: 'Send',
    tab: 'Tab',
    tryAgain: 'Try again',
    unexpectedError: 'There was an unexpected error. Please try again',
    validUpTo: 'Valid up to',
    vendorRole: 'Vendor',
    view: 'View',
  },
  filterOptions: {
    accountsVerifications: 'Accounts Verifications',
    accountVerifications: 'Account verifications',
    adApprovals: 'Ad approvals',
    adsApprovals: 'Ads Approvals',
    contactUs: 'Contact Us',
    dealApprovals: 'Deal approvals',
    dealsApprovals: 'Deals Approvals',
    messagesReports: 'Messages Reports',
    postsReports: 'Posts Reports',
    resolvedReports: 'Resolved Reports',
    state: 'State',
    type: 'Type',
    unresolvedTasks: 'Unresolved Tasks',
    urgentTasks: 'Urgent Tasks',
  },
  indicators: {
    accountVerification: 'Account verification',
    adAproval: 'Ad aproval',
    contactUs: 'Contact Us',
    dealAproval: 'Deal aproval',
    urgentTasks: 'Urgent tasks',
    total: 'Total Unresolved Tasks',
  },
  userReports: {
    discriminatory: 'Discriminatory',
    falseInformation: 'False Information',
    harassment: 'Harassment',
    nudity: 'Nudity',
    other: 'Something Else',
    spam: 'Spam',
    SuicideSelfInjury: 'Suicide or Self-Injury',
    terrorism: 'Terrorism',
    unauthorizedSales: 'Unauthorized sales',
    violence: 'Violence',
  },
  userProfile: {
    greetingUser: 'Hello',
  },
  tabsModals: {
    tabs: {
      tabBasicInfo: 'Basic info',
      tabBusinessHours: 'Business hours',
      tabContactInfo: 'Contact info',
      tabDetails: 'Details',
      tabImages: 'Images',
      tabLocations: 'Locations',
      tabVendorDetails: 'Vendor Details',
    },
    titles: {
      titleBasicInfo: 'Basic info',
      titleContactUs: 'Contact Us',
      titleHours: 'Business hours',
      titleInfo: 'Info',
      titleLocations: 'Locations',
      titleMessage: 'Message',
      titleRequestEdits: 'Request Edits',
      titleRespond: 'Respond',
    },
    fields: {
      fieldBannerImage: 'Banner image',
      fieldBirthDate: 'Birth date',
      fieldBusinessImages: 'Business images',
      fieldBusinessName: 'Business name',
      fieldCaption: 'Caption',
      fieldCaptionUrl: 'Caption URL',
      fieldContactEmail: 'Contact email',
      fieldEmail: 'Email',
      fieldFacebook: 'Facebook',
      fieldFirstName: 'First Name',
      fieldFriday: 'Friday',
      fieldImageOrVideo: 'Image or Video',
      fieldInstagram: 'Instagram',
      fieldLastName: 'Last Name',
      fieldMobileNumber: 'Mobile number',
      fieldMonday: 'Monday',
      fieldNickName: 'Location nickname',
      fieldNote: 'Note',
      fieldPhoneNumber: 'Phone number',
      fieldPostedBy: 'Posted by',
      fieldProfileImage: 'Profile image',
      fieldReason: 'Reason',
      fieldSaturday: 'Saturday',
      fieldSunday: 'Sunday',
      fieldThursday: 'Thursday',
      fieldTitle: 'Title',
      fieldTuesday: 'Tuesday',
      fieldTwitter: 'Twitter',
      fieldWebsiteUrl: 'Website URL',
      fieldWednesday: 'Wednesday',
    },
    buttons: {
      btnMarkResolved: 'Mark as resolved',
      btnRespond: 'Respond',
    },
  },
  taskManagement: {
    accountInfoTab: 'Account Info',
    accountReportDescription:
      'Please check the reports details and dates, as well as the full information about both the reported account and the reporter(s).',
    accountReportTitle: 'Account Report',
    accountSuccessfullyVerified: 'Account successfully verified',
    accountVerificationRejected: 'Account verification rejected',
    accountVerified: 'Account successfully verified',
    adApproval: 'Ad approval',
    adCallToAction: 'Call to Action',
    adConfirmationMessage:
      'This ad will be published and the vendor will receive an email notification.',
    adConfirmationRejectMessage:
      'This ad will be declined and the vendor will receive an email notification.',
    adConfirmedMessage: 'Ad successfully verified',
    adDescription: 'Description',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    addressTab: 'Address',
    adEndDate: 'Ending',
    adHeadline: 'Headline',
    adLocation: 'Location',
    adPackage: 'Package',
    adRejectMessage: 'Ad verification rejected',
    adRequestEditsMessage: 'Changes successfully requested',
    adStartDate: 'Start Date',
    adVerificationRejected: 'Ad verification rejected',
    adWeeks: 'Weeks',
    approvalDescription:
      'Please make sure that the information bellow matches the information of their rental \nand lease agreement before verifying this account.',
    approveAd: 'Approve Ad',
    approveButton: 'Approve',
    approveDeal: 'Approve Deal',
    aptSuit: 'Apt/Suit',
    birthDate: 'Birth date',
    businessCategory: 'Category',
    businessDescription: 'Business description',
    businessEmail: 'Business email',
    businessInformationTitle: 'Business Information',
    businessName: 'Business name',
    businessStage: 'Stage',
    businessSubCategory: 'Sub-Category',
    city: 'City',
    commentReportTitle: 'Comment Report',
    commentReportType: 'Comment',
    communityTab: 'Community',
    conditionsTab: 'Conditions',
    confirmationBusinessApproveDetail:
      'Changes for this business will be authorized once \n you confirm this action.',
    confirmationBusinessDeclineDetail:
      'Changes for this business will not be authorized once \n you confirm this action.',
    confirmationTaskApproveDetail:
      'This account will be verified once \n your confirm this action.',
    confirmationTaskDeclineDetail:
      "This account won't be verified once \n your confirm this action.",
    confirmationTaskContactUsRejected:
      'This message will be rejected and the sender will not receive any response.',
    confirmationTaskContactUsResolved:
      'This message will be marked as resolved and the sender will not receive any response.',
    confirmationTaskContactUsRespond:
      'This message will be sent and the sender will receive the response by email.',
    confirmationTaskQuestion: 'Are you sure?',
    contactBirthDate: 'Birth date',
    contactBusinessButtonLabel: 'Contact {{businessName}}',
    contactEmail: 'Email',
    contactFirstName: 'First Name',
    contactLastName: 'Last Name',
    contactMobileNumber: 'Mobile number',
    contactPersonTab: 'Contact Person',
    contactTitle: 'Title',
    contactTitleLabel: 'Title',
    contactVendorTab: 'Contact Vendor',
    country: 'Country',
    dealAproval: 'Deal approval',
    dealAudience: 'Audience',
    dealCodeUrl: 'Code URL',
    dealConfirmationMessage:
      'This deal will be published and the vendor will receive an email notification.',
    dealConfirmationRejectMessage:
      'This deal will be published and the vendor will receive an email notification.',
    dealConfirmedMessage: 'Deal successfully verified',
    dealEndDate: 'Ending',
    dealLocation: 'Location',
    dealOfferTag: 'Offer Tag',
    dealPackage: 'Package',
    dealPreviewFooterTitle: 'Details of this order',
    dealPreviewScreenName: 'Deal deatails',
    dealPromoCode: 'Promo Code',
    dealQrAndPromo: 'QR Code and Promo Code',
    dealQrCode: 'QR Code',
    dealRejectMessage: 'Deal verification rejected',
    dealRequestEditsMessage: 'Changes successfully requested',
    dealStartDate: 'Start Date',
    dealType: 'Deal type',
    dealVerificationRejected: 'Deal verification rejected',
    dealWeeks: 'Weeks',
    detailsTab: 'Details',
    durationTab: 'Duration',
    email: 'Email',
    firstName: 'First name',
    generalTab: 'General',
    ignoreConfirmationMessage: 'You are about to ignore this report.',
    ignoreReport: 'Ignore',
    ignoreReportButtonLabel: 'Ignore this report',
    lastName: 'Last name',
    locationTab: 'Location',
    mobileNumber: 'Mobile number',
    neighborhood: 'Neighborhood',
    newTaskAdded: 'New task has been added',
    noTask: 'Kudos! There are no tasks \n that need your attention',
    personalInformationTitle: 'Personal information',
    postReportTitle: 'Post Report',
    postReportType: 'Post',
    previewTab: 'Preview',
    reject: 'Reject',
    rejectAd: 'Reject Ad',
    rejectDeal: 'Reject Deal',
    report: 'Report',
    reportAccountCreatedDate: 'Mana Member since',
    reportAccountEmail: 'Email',
    reportAccountName: 'Name',
    reportAccountPhone: 'Phone Number',
    reportAditionalCommentLabel: 'Additional Comment',
    reportBusinessCategoryLabel: 'Category',
    reportBusinessDescriptionLabel: 'Business description',
    reportBusinessNameLabel: 'Business name',
    reportCommentPostTab: 'View Comment',
    reportDateAndTimeLabel: 'Date & Time',
    reportedComment: 'Reported comment',
    reportHistoryDetail: '{{name}}’s {{type}} was reported for {{reason}}',
    reportIgnoredConfirmationModal: 'Report ignored',
    reportReasonLabel: 'Reason',
    reportReportedAccountTab: 'Reported Account',
    reportReportedByLabel: 'Reported by',
    reportReporterAccountTab: 'Reporter Account',
    reportReportsHistoryTab: 'Reports History',
    reportRevoqueConfirmationModal: 'User access revoked successfully',
    reportsAccountTitle: 'Account information',
    reportsBusinessAccountTitle: 'Business information',
    reportsHistoryTitle: 'Reports History',
    reportThisReportTab: 'This Report',
    reportViewCommentTab: 'View Comment',
    reportViewPostTab: 'View Post',
    requestEdits: 'Request Edits',
    requestEditsModalDescriptionAd:
      'Send the advertiser a detailed message requesting the edits needed to approve their {{typeTask}}. Try to be as specific as possible by detailing \n which field you want them to update (Ex. Title, Description, Duration...)',
    requestEditsModalDescriptionContactUs:
      'Respond to the user to gather the information you need. Try to be as accurate as possible. This response will be sent as an email from this <strong>"{{adminEmail}}"</strong> to <strong>"{{userEmail}}"</strong>.',
    resolved: 'Resolved',
    revokeButtonLabel: 'Revoke {{businessName}}’s access',
    revokeConfirmationMessage:
      'The account access will be revoked and the owner will receive an email',
    selectCommunityTitle: 'Please select a Community for this vendor',
    sendMessage: 'Send message',
    state: 'State',
    streetAddress: 'Street address',
    taskContactUsRejectedMessage: 'Message verification rejected',
    taskContactUsResolvedMessage: 'Message successfully resolved',
    taskContactUsRespondMessage: 'Message successfully respond',
    tasksCount: 'tasks',
    taskTypeAccountVerification: 'Account verification',
    taskTypeAdApproval: 'Ad approval',
    taskTypeBusinessUpdate: 'New Business Verification',
    taskTypeContactUs: 'Contact Us',
    taskTypeDealApproval: 'Deal approval',
    thisReportLabel: 'This report ({{calendar}})',
    title: 'Task management',
    unresolved: 'Unresolved',
    urgent: 'Urgent',
    userVerificationTaskSubTitle: 'Requested on',
    userVerificationTaskTitleMessage: 'has requested an account verification',
    verifyAccountDescription:
      'Please make sure that the information bellow matches the information of their rental \n and lease agreement before verifying this account.',
    verifyAccountTitle: 'Verify Account',
    verifyBusinessTitle: 'Verify business',
    verifyContactUsTitle: 'Contact Us',
    verifyContactUsDescription:
      'Please check that the information below matches the information given by the \n user before marking the ticket as solved. <br> You can respond if you need further information.',
    zipCode: 'Zipcode',
  },
  tiers: {
    btnCancel: 'Cancel',
    btnDelete: 'Delete this Tier',
    btnDiscardChanges: 'Discard changes and go back',
    btnModify: 'Modify Details',
    btnSave: 'Save changes',
    confirmationMessageCreate: 'You are about to create a new package.',
    confirmationMessageDelete: 'You are about to delete a package.',
    confirmationMessageUpdate: 'You are about to update a package.',
    duration: 'Duration',
    durationFieldPlaceholder: 'Please select a duration',
    impressionFieldPlaceholder: 'Please select an impression',
    impressions: 'Impressions',
    modalTitle: 'Tier Details',
    name: 'Tier Name',
    noTiers: 'Kudos! There are no tiers',
    optionsSelect: {
      tierType: {
        ad: 'Ad',
        deal: 'Deal',
      },
      duration: {
        week1: '1 week',
        week2: '2 weeks',
        week4: '4 weeks',
        week8: '8 weeks',
      },
      impression: {
        perDay1: '1 per user per day',
        perDay2: '2 per user per day',
        perDay4: '4 per user per day',
        perDay8: '8 per user per day',
      },
    },
    perUserPerDay: 'per user per day',
    notificationMessageCreate: 'Tier successfully created',
    notificationMessageUpdate: 'Tier successfully updated',
    notificationMessageDelete: 'Tier deleted',
    notificationMessageNotDelete: 'Cannot remove this package',
    price: 'Price',
    type: 'Type',
    week: 'week',
  },
  manaNews: {
    btnCancel: 'Cancel',
    btnDelete: 'Delete',
    commentsOnPost: 'comments on post',
    contentPosted: 'Content posted!',
    deleteDescription: 'Are you sure you want to permanently delete this post?',
    deletePost: 'Delete post',
    fieldCommunityPlaceholder: 'Please select a community',
    fieldPostedByPlaceholder: 'Please select an user',
    modalTitle: 'Create Mana News',
    noManaNew: 'Kudos! There are no Mana news',
    notificationMessageCreate: 'Post successfully created',
    notificationMessageDelete: 'Post deleted',
    postError: 'Posting error!',
    posting: 'Posting...',
    reply: 'Reply',
    sendReply: 'Send reply',
    visitLink: 'Visit link',
  },
  errorsMessage: {
    errorEmailAlreadyAssociate:
      'This email is already associated with an account',
    errorNetwork: "We're having trouble connecting to the network",
    errorProcessingFile: 'An error occurred while processing the file',
    invalidCredentials: 'Invalid password',
    invalidEmailFormat: 'Invalid email format',
    invalidUrlFormat: 'URL is not valid. Format should be: “https://....”',
  },
  events: {
    noEvent: 'Kudos! There are no events \n that need your attention',
    notificationMessageChanged: 'Community successfully changed',
  },
  reports: {
    noReport: 'Kudos! There are no reports \n that need your attention',
  },
  contactUsRequestType: {
    copyrightCounterNotice: 'Copyright counter notice',
    copyrightInfrigementDMCA: 'Copyright infringement/DMCA',
    iNeedHelpAccount: 'I need help with my account',
    inquiriesRelatedYourPrivacyRights:
      'Inquiries related to your privacy rights',
    investigations: 'Investigations',
    mobileHelp: 'Mobile help',
    otherHelp: 'Other help',
    problemsAdsDeals: 'Problems with ads/deals',
    purchaseProblemTicketFairy: 'Purchase problem with ticket fairy',
    purchasesInquiries: 'Purchases Inquiries',
    reportBug: 'Report bug',
    reportChildSexualExploitation: 'Report child sexual exploitation',
    reportContentPolicyViolation: 'Report content policy violation',
    reportNSFW18PlusUserContentMessage:
      'Report NSFW/18+ users, content or messages',
    reportSuicideSelfHarm: 'Report suicide/self-harm',
    reportViolationCodeConduct: 'Report a violation code of conduct',
    trademarkRequest: 'Trademark request',
  },
  adminUsers: {
    addNewUser: 'Add New User',
    confirmationDeleteAdminUserQuestion: 'Are you sure?',
    confirmationMessageDelete: 'You are about to delete an user.',
    createUserSuccessMessage: 'User successfully created',
    deleteUserSuccessMessage: 'User role deleted successfully',
    editUser: 'Edit User',
    editUserSuccessMessage: 'User role edited successfully',
    errorMessage: 'An error occurred in the request',
    modalDescription: 'Invitation will be send to the email',
    noUsers: 'Kudos! There are no users \n that need your attention',
    rolePlaceholder: 'Please select a role',
    searchLabel: 'You can search by Name, Email',
    viewUser: 'View User',
  },
  userStatus: {
    active: 'Active',
    deactivated: 'Deactivated',
    deleted: 'Deleted',
    inactive: 'Inactive',
    pending: 'Pending',
    pendingEmailConfirmation: 'Pending Email Confirmation',
    suspended: 'Suspended',
    unverified: 'Unverified',
    verified: 'Verified',
  },
};
