import {ITableColumns} from '../models/interfaces/table';
import {
  TaskManagerHeaders,
  TaskManagerAccesors,
} from '../models/enums/taskTable';
import {
  TiersHeaders,
  TiersAccesors,
  ReportsHeaders,
  ReportsAccesors,
} from '../models/enums/taskTable';
import {
  UsersAdminAccesors,
  NewsHeaders,
  NewsAccesors,
} from '../models/enums/taskTable';
import {
  EventHeader,
  EventAccesors,
  UsersAdminHeader,
} from '../models/enums/taskTable';

export const taskTableColumns: ITableColumns[] = [
  {
    Header: TaskManagerHeaders.Name,
    accessor: TaskManagerAccesors.Name,
  },
  {
    Header: TaskManagerHeaders.TaskType,
    accessor: TaskManagerAccesors.TaskType,
  },
  {
    Header: TaskManagerHeaders.DateRequest,
    accessor: TaskManagerAccesors.DateRequest,
  },
  {
    Header: TaskManagerHeaders.Status,
    accessor: TaskManagerAccesors.Status,
  },
  {
    Header: TaskManagerHeaders.Actions,
    accessor: TaskManagerAccesors.Actions,
  },
];

export const reportsTableColumns: ITableColumns[] = [
  {
    Header: ReportsHeaders.ReportedAccount,
    accessor: ReportsAccesors.ReportedAccount,
  },
  {
    Header: ReportsHeaders.ReportType,
    accessor: ReportsAccesors.ReportType,
  },
  {
    Header: ReportsHeaders.Date,
    accessor: ReportsAccesors.Date,
  },
  {
    Header: ReportsHeaders.Status,
    accessor: ReportsAccesors.Status,
  },
  {
    Header: ReportsHeaders.Actions,
    accessor: ReportsAccesors.Actions,
  },
];

export const tiersTableColumns: ITableColumns[] = [
  {
    Header: TiersHeaders.TierName,
    accessor: TiersAccesors.TierName,
  },
  {
    Header: TiersHeaders.TierType,
    accessor: TiersAccesors.TierType,
  },
  {
    Header: TiersHeaders.Price,
    accessor: TiersAccesors.Price,
  },
  {
    Header: TiersHeaders.Duration,
    accessor: TiersAccesors.Duration,
  },
  {
    Header: TiersHeaders.Impressions,
    accessor: TiersAccesors.Impressions,
  },
  {
    Header: TiersHeaders.Actions,
    accessor: TiersAccesors.Actions,
  },
];

export const newsTableColumns: ITableColumns[] = [
  {
    Header: NewsHeaders.PostedBy,
    accessor: NewsAccesors.PostedBy,
  },
  {
    Header: NewsHeaders.Caption,
    accessor: NewsAccesors.Caption,
  },
  {
    Header: NewsHeaders.DatePosted,
    accessor: NewsAccesors.DatePosted,
  },
  {
    Header: NewsHeaders.Reactions,
    accessor: NewsAccesors.Reactions,
  },
  {
    Header: NewsHeaders.Comments,
    accessor: NewsAccesors.Comments,
  },
  {
    Header: NewsHeaders.Deletion,
    accessor: NewsAccesors.Deletion,
  },
];

export const eventsTableColumns: ITableColumns[] = [
  {Header: EventHeader.Name, accessor: EventAccesors.Name},
  {Header: EventHeader.DateAndTime, accessor: EventAccesors.DateAndTime},
  {Header: EventHeader.Location, accessor: EventAccesors.Location},
  {Header: EventHeader.Community, accessor: EventAccesors.Community},
];

export const usersTableColumns: ITableColumns[] = [
  {Header: UsersAdminHeader.Name, accessor: UsersAdminAccesors.Name},
  {Header: UsersAdminHeader.Role, accessor: UsersAdminAccesors.Role},
  {Header: UsersAdminHeader.Email, accessor: UsersAdminAccesors.Email},
  {Header: UsersAdminHeader.Status, accessor: UsersAdminAccesors.Status},
  {Header: UsersAdminHeader.Actions, accessor: UsersAdminAccesors.Actions},
];
