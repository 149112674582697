import React, {useEffect, useState} from 'react';
import {buildTierData} from './data';
import {EConfirmationModalIcons} from '../../models/enums/confirmationModal';
import {ITierData} from '../../models/interfaces/tierData';
import {ITierListRow, useTierList} from '../../hooks/useTierList';
import {showPopup} from '../../redux/actions/uiActions';
import {TaskReportType} from '../../models/enums/taskManagerTaskType';
import {tiersTableColumns} from '../../constants/tableColumns';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  createPackage,
  deletePackage,
  updatePackage,
} from '../../services/api/tierAPI';
import {
  ManageTierOperation,
  PackageType,
  TierAction,
} from '../../models/enums/tierState';
import {
  ITierConfirmData,
  ITierEditableFields,
} from '../../models/interfaces/tiers';
import {
  generalModalStyles,
  editModalStyles,
  confirmModalStyles,
} from '../../constants/styles';
import ConfirmationModal from '../../components/ConfirmationModal';
import DetailsEdits from '../../components/DetailsEdits';
import HeaderContent from '../../components/HeaderContent';
import LeftMenu from '../../components/LeftMenu';
import ManageAction from './Components/Actions';
import ManageTier from '../../components/ManageTiers';
import Modal from 'react-modal';
import NoResults from '../TaskManagerPage/components/NoResults';
import Table from '../../components/Table';
import './styles.scss';

const TierPage = () => {
  // Hooks
  const {t} = useTranslation();
  const {list, handleGetList} = useTierList();
  const dispatch = useDispatch();

  // States
  const [data, setData] = useState<ITierData[] | []>([]);
  const [dataConfirm, setDataConfirm] = useState<ITierConfirmData>({
    id: null,
    packageType: PackageType.Ad,
    action: TierAction.Create,
  });
  const [editsIsOpen, setEditsIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState<boolean>(true);
  const [createProcess, setCreateProcess] = useState(false);
  const [packageAction, setPackageAction] = useState<TierAction>();
  const [editableFields, setEditableFields] = useState<ITierEditableFields[]>();
  const [selectedPackage, setSelectedPackage] = useState<ITierListRow | null>();
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [nameValue, setNameValue] = useState<string>('');
  const [priceValue, setPriceValue] = useState<number>(0);
  const [durationValue, setDurationValue] = useState<number>(0);
  const [packageTypeValue, setPackageTypeValue] = useState<PackageType>(1);
  const [impressionsValue, setImpressionsValue] = useState<number>(0);

  // Effects
  useEffect(() => {
    const record: ITierData[] = [];

    list.forEach(tier => {
      const tierType =
        tier.packageType === PackageType.Ad
          ? t('tiers:optionsSelect:tierType:ad')
          : t('tiers:optionsSelect:tierType:deal');

      const price = `$ ${tier.price}`;

      const duration = `${tier.durationWeeks} ${t('tiers:week')}`;

      const impressions =
        tier.packageType === PackageType.Deal
          ? t('global:nA')
          : `${tier.impressions} ${t('tiers:perUserPerDay')}`;

      record.push({
        tierName: tier.name,
        tierType,
        price,
        duration,
        impressions,
        actions: (
          <ManageAction
            tierId={tier.id}
            packageType={tier.packageType}
            packageData={tier}
            text={t('global:manage')}
            handleOpenDetails={handleOpenDetails}
          />
        ),
      });
    });
    setData(record);
  }, [list]);

  useEffect(() => {
    setNameValue(nameValue);
    setPackageTypeValue(packageTypeValue);
    setPriceValue(priceValue);
    setDurationValue(durationValue);
    setImpressionsValue(impressionsValue);
    setEditableFields([
      {
        value: nameValue,
        setValue: setNameValue,
      },
      {
        value: packageTypeValue,
        setValue: setPackageTypeValue,
      },
      {
        value: priceValue,
        setValue: setPriceValue,
      },
      {
        value: durationValue,
        setValue: setDurationValue,
      },
      {
        value: impressionsValue,
        setValue: setImpressionsValue,
      },
    ]);
    if (
      packageTypeValue === PackageType.Ad &&
      nameValue &&
      !isNaN(priceValue) &&
      durationValue > 0 &&
      impressionsValue > 0
    ) {
      setIsDisabledBtn(false);
    } else if (
      packageTypeValue === PackageType.Deal &&
      nameValue &&
      !isNaN(priceValue) &&
      durationValue > 0
    ) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [
    nameValue,
    packageTypeValue,
    priceValue,
    durationValue,
    impressionsValue,
  ]);

  // Utils - Handlers
  const handleOpenDetails = async (tier: ITierListRow) => {
    try {
      if (tier) {
        const {name, packageType, price, durationWeeks, impressions} = tier;

        setSelectedPackage(tier);
        setNameValue(name);
        setPackageTypeValue(packageType);
        setPriceValue(price);
        setDurationValue(durationWeeks);
        setImpressionsValue(impressions);
        setModalIsOpen(true);
        setCreateProcess(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRequestData = async (
    id: number | null,
    packageType: PackageType,
    action: TierAction,
  ) => {
    setDataConfirm({
      id,
      packageType,
      action,
    });
    setPackageAction(action);
    setConfirmationIsOpen(true);

    if (action === TierAction.Delete) {
      setModalIsOpen(true);
    } else {
      setEditsIsOpen(true);
    }
  };

  const handleCreateNew = () => {
    setNameValue('');
    setPackageTypeValue(1);
    setPriceValue(0);
    setDurationValue(0);
    setImpressionsValue(0);
    setSelectedPackage(null);
    setCreateProcess(true);
    setEditsIsOpen(true);
  };

  const handleConfirmTask = async (data: ITierConfirmData) => {
    if (data) {
      try {
        const {id, action, packageType} = data;

        if (action === TierAction.Edit && id) {
          await updatePackage({
            id,
            name: nameValue,
            packageType,
            price: priceValue,
            durationWeeks: durationValue,
            impressions: impressionsValue,
          });
        } else if (action === TierAction.Delete && id) {
          if (validateDeletePackages(packageType)) {
            await deletePackage({
              id,
            });
          } else {
            handleCleanModals();
            return;
          }
        } else {
          await createPackage({
            name: nameValue,
            packageType,
            price: priceValue,
            durationWeeks: durationValue,
            impressions: impressionsValue,
          });
        }
        handleCleanModals();
        showPopupMessage(action);
        handleGetList();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateDeletePackages = (packageType: PackageType) => {
    const adPackagesList = list.filter(e => e.packageType === PackageType.Ad);
    const dealPackagesList = list.filter(
      e => e.packageType === PackageType.Deal,
    );

    if (
      (packageType === PackageType.Ad && adPackagesList.length === 1) ||
      (packageType === PackageType.Deal && dealPackagesList.length === 1)
    ) {
      dispatch(
        showPopup(t('tiers:notificationMessageNotDelete'), 3000, 'info'),
      );
      return false;
    }
    return true;
  };

  const getConfirmationMessage = () => {
    if (TierAction.Create === packageAction) {
      return t('tiers:confirmationMessageCreate');
    } else if (TierAction.Edit === packageAction) {
      return t('tiers:confirmationMessageUpdate');
    }
    return t('tiers:confirmationMessageDelete');
  };

  const handleCleanValues = () => {
    setNameValue(selectedPackage?.name || '');
    setPackageTypeValue(selectedPackage?.packageType || 0);
    setPriceValue(selectedPackage?.price || 0);
    setDurationValue(selectedPackage?.durationWeeks || 0);
    setImpressionsValue(selectedPackage?.impressions || 0);
  };

  const showPopupMessage = (action: TierAction) => {
    if (action === TierAction.Delete) {
      dispatch(showPopup(t('tiers:notificationMessageDelete'), 3000, 'error'));
    } else {
      const message =
        action === TierAction.Edit
          ? t('tiers:notificationMessageUpdate')
          : t('tiers:notificationMessageCreate');
      dispatch(showPopup(message, 3000, 'success'));
    }
  };

  const handleCleanModals = () => {
    setConfirmationIsOpen(false);
    setModalIsOpen(false);
    setEditsIsOpen(false);
  };

  return (
    <div data-testid={'reports-page'} className="reports-page" id="layout">
      <LeftMenu />
      <div className="page-content">
        <HeaderContent
          createNew
          onClick={handleCreateNew}
          showFilters={false}
          title={t('menu:tiers')}
          titleFilter={t('global:filterTiers')}
          typeReport={TaskReportType.Report}
        />

        <Table
          columns={tiersTableColumns}
          data={data}
          noResults={<NoResults text={t('tiers:noTiers')} />}
        />
      </div>

      {modalIsOpen && selectedPackage && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={generalModalStyles}>
          <ManageTier
            data={buildTierData(t, selectedPackage, 'READ')}
            tierId={selectedPackage?.id || null}
            packageType={selectedPackage?.packageType || packageTypeValue}
            onCloseModal={() => setModalIsOpen(false)}
            operations={[
              {
                type: ManageTierOperation.deleteTier,
                color: 'red-outlined',
                label: t('tiers:btnDelete'),
              },
              {
                type: ManageTierOperation.modifyDetail,
                color: 'blue-outlined',
                label: t('tiers:btnModify'),
              },
            ]}
            onRequestData={onRequestData}
            onRequestEdits={() => {
              setModalIsOpen(false);
              setEditsIsOpen(true);
            }}
          />
        </Modal>
      )}

      {editsIsOpen && (
        <Modal
          isOpen={editsIsOpen}
          onRequestClose={() => {
            setEditsIsOpen(false);
          }}
          style={editModalStyles}>
          <DetailsEdits
            title={t('tiers:modalTitle')}
            data={
              selectedPackage
                ? buildTierData(t, selectedPackage, 'EDIT', editableFields)
                : buildTierData(t, null, 'CREATE', editableFields)
            }
            tierId={selectedPackage?.id || null}
            packageType={selectedPackage?.packageType || packageTypeValue}
            createProcess={createProcess}
            isDisabledBtn={isDisabledBtn}
            onGoBack={() => {
              handleCleanValues();
              setEditsIsOpen(false);
              setModalIsOpen(true);
            }}
            onSend={(id, packageType, action) => {
              onRequestData(id, packageType, action);
            }}
            onCloseModal={() => setEditsIsOpen(false)}
          />
        </Modal>
      )}

      {confirmationIsOpen && (
        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={() => setConfirmationIsOpen(false)}
          style={confirmModalStyles}>
          <ConfirmationModal
            question={t('taskManagement:confirmationTaskQuestion')}
            detail={getConfirmationMessage()}
            icon={
              packageAction === TierAction.Delete
                ? EConfirmationModalIcons.Cross
                : EConfirmationModalIcons.Check
            }
            onConfirm={() => handleConfirmTask(dataConfirm)}
            onCancel={() => setConfirmationIsOpen(false)}
            onClose={() => setConfirmationIsOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default TierPage;
