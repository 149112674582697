import {EMenuOptions} from '../models/enums/menu';

export const routesEnabledForEachRol = {
  superAdmin: [
    EMenuOptions.Dashboard,
    EMenuOptions.Events,
    EMenuOptions.ManaNews,
    EMenuOptions.Reports,
    EMenuOptions.Root,
    EMenuOptions.TaskManager,
    EMenuOptions.Tiers,
    EMenuOptions.Users,
  ],
  editor: [EMenuOptions.ManaNews],
  verificator: [EMenuOptions.TaskManager],
};
