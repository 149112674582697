import React from 'react';
import {EConfirmationModalIcons} from '../../models/enums/confirmationModal';
import {EInputsIds} from '../../models/enums/inputsId';
import {ETypeActions} from '../../models/enums/generals';
import {ETypeForms} from '../../models/enums/forms';
import {generalModalStyles, confirmModalStyles} from '../../constants/styles';
import {getUserUiInfoModalForm} from '../../constants/getTexts';
import {TaskReportType} from '../../models/enums/taskManagerTaskType';
import {usersTableColumns} from '../../constants/tableColumns';
import {useTranslation} from 'react-i18next';
import ConfirmationModal from '../../components/ConfirmationModal/index';
import HeaderContent from '../../components/HeaderContent';
import InputSearch from '../../components/InputSearch';
import LeftMenu from '../../components/LeftMenu';
import Modal from 'react-modal';
import ModalForm from '../../components/ModalForm/index';
import NoResults from '../TaskManagerPage/components/NoResults/index';
import Table from '../../components/Table/index';
import UserForm from './Components/Form/index';
import useUsersList from '../../hooks/useUsersList';
import './styles.scss';

const UserPage = () => {
  const {t} = useTranslation();
  const {
    adminRolesOptions,
    adminUserId,
    buildedData,
    handleClearUserData,
    handleCreateNew,
    handleDelete,
    handleEdit,
    onSearchFilter,
    searchFilter,
    setShowConfirmationModal,
    setShowModal,
    setTypeAction,
    showConfirmationModal,
    showModal,
    typeAction,
  } = useUsersList();

  return (
    <div data-testid={'user-page'} className="user-page" id="layout">
      <LeftMenu />
      <div className="page-content">
        <HeaderContent
          createNew
          onClick={() => {
            setShowModal(true);
            setTypeAction(ETypeActions.Create);
          }}
          showFilters={false}
          title={t('menu:users')}
          typeReport={TaskReportType.User}
        />
        <InputSearch
          id={EInputsIds.Search}
          value={searchFilter}
          onChange={onSearchFilter}
        />
        <Table
          columns={usersTableColumns}
          data={buildedData}
          noResults={<NoResults text={t('adminUsers:noUsers')} />}
        />
      </div>

      {showModal && (
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          style={{
            ...generalModalStyles,
            content: {...generalModalStyles.content, width: '450px'},
          }}>
          <ModalForm
            description={
              typeAction !== ETypeActions.View
                ? t('adminUsers:modalDescription')
                : ''
            }
            form={
              <UserForm
                adminRolesOptions={adminRolesOptions || []}
                handleClearUserData={handleClearUserData}
                handleCreateNew={handleCreateNew}
                setShowModal={setShowModal}
                typeAction={typeAction}
                handleEdit={handleEdit}
              />
            }
            icon={getUserUiInfoModalForm(ETypeForms.AdminUser, typeAction).icon}
            handleCloseModal={() => setShowModal(false)}
            title={
              getUserUiInfoModalForm(ETypeForms.AdminUser, typeAction).title
            }
          />
        </Modal>
      )}

      {showConfirmationModal && (
        <Modal
          isOpen={showConfirmationModal}
          onRequestClose={() => setShowConfirmationModal(false)}
          style={confirmModalStyles}>
          <ConfirmationModal
            question={t('adminUsers:confirmationDeleteAdminUserQuestion')}
            detail={t('adminUsers:confirmationMessageDelete')}
            icon={EConfirmationModalIcons.Cross}
            onConfirm={() => handleDelete(adminUserId)}
            onCancel={() => setShowConfirmationModal(false)}
            onClose={() => setShowConfirmationModal(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserPage;
