import React from 'react';
import Iframe from '../../components/Iframe';
import LeftMenu from '../../components/LeftMenu';
import {URL_DATA_STUDIO_DASHBOARD} from '../../constants/urls';
import './styles.scss';

const DashboardPage = () => {
  return (
    <div
      data-testid={'task-manager-page'}
      className="task-manager-page"
      id="layout">
      <LeftMenu />

      <div className="page-content-dashboard">
        <Iframe src={URL_DATA_STUDIO_DASHBOARD} />
      </div>
    </div>
  );
};

export default DashboardPage;
