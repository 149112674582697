import {
  IEvent,
  IEventListRequest,
  IEventUpdateCommunityRequest,
} from '../../models/api/event';
import {EventOrderBy} from '../../models/enums/eventOrderBy';
import {getEventsList} from '../../services/api/eventApi';
import {updateEventCommunity as updateEventCommunityService} from '../../services/api/eventApi';

import {
  CLEAR_EVENT_LIST_PARAMS,
  CLEAR_EVENT_STATE,
  SET_EVENT_LIST_SORTING,
  SET_EVENT_LIST_IS_LOADING,
  SET_EVENT_LIST,
  EventActionTypes,
  SET_EVENT_CURRENT_PAGE,
  SET_EVENT_COMMUNITY,
} from '../types/eventListTypes';

export const setEventList = (payload: IEvent[]): EventActionTypes => ({
  type: SET_EVENT_LIST,
  payload,
});

export const clearEventListParams = (): EventActionTypes => ({
  type: CLEAR_EVENT_LIST_PARAMS,
});

export const clearEventState = (): EventActionTypes => ({
  type: CLEAR_EVENT_STATE,
});

export const setEventListSorting = (
  payload: EventOrderBy,
): EventActionTypes => ({
  type: SET_EVENT_LIST_SORTING,
  payload,
});

export const setEventListIsLoading = (payload: boolean): EventActionTypes => ({
  type: SET_EVENT_LIST_IS_LOADING,
  payload,
});

export const setEventCurrentPage = (payload: number): EventActionTypes => ({
  type: SET_EVENT_CURRENT_PAGE,
  payload,
});

export const setEventCommunity = (payload: IEvent): EventActionTypes => ({
  type: SET_EVENT_COMMUNITY,
  payload,
});

export const getAndSaveEvents =
  (data: IEventListRequest) => async (dispatch: any, getState: any) => {
    try {
      const {
        event: {isLoading},
      } = getState();

      if (isLoading) {
        return;
      }

      dispatch(setEventListIsLoading(true));

      const response = await getEventsList(data);

      dispatch(setEventList(response.data));
      dispatch(setEventListIsLoading(false));
    } catch (err) {
      dispatch(setEventListIsLoading(false));

      console.log('Error: Fail on get Events: ', err);
    }
  };

export const updateEventCommunity =
  (data: IEventUpdateCommunityRequest) =>
  async (dispatch: any, getState: any) => {
    try {
      const {
        event: {isLoading},
      } = getState();

      if (isLoading) {
        return;
      }
      dispatch(setEventListIsLoading(true));

      const response = await updateEventCommunityService(data);

      dispatch(setEventCommunity(response.data));
      dispatch(setEventListIsLoading(false));
    } catch (err) {
      dispatch(setEventListIsLoading(false));

      console.log(`Error: Fail on update event ${data.eventId}: `, err);
    }
  };
