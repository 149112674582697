import React, {useEffect, useState} from 'react';
import {AppState} from './redux';
import {BrowserTracing} from '@sentry/tracing';
import {EMenuOptions} from './models/enums/menu';
import {getAdminUserRoles} from './redux/actions/userAdminActions';
import {getAllowedRoutesForUserRole} from './utils/getAuthorizedRole';
import {manaGlobalObject} from './constants/objects';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import {useAuth} from './hooks/useAuth';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  loadCommunityData,
  loadUserInformation,
} from './redux/actions/userActions';
import * as Sentry from '@sentry/react';
import ConfirmationPopup from './components/ConfirmationPopup';
import DashboardPage from './pages/DashboardPage';
import EventsPage from './pages/EventsPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import LoginPage from './pages/LoginPage';
import Modal from 'react-modal';
import NewPasswordPage from './pages/NewPasswordPage/index';
import NewsPage from './pages/NewsPage';
import pages from './constants/pages';
import ReportingPage from './pages/ReportingPage';
import RequireAuth from './components/RequireAuth';
import TaskManagerPage from './pages/TaskManagerPage';
import TierPage from './pages/TierPage';
import UserPage from './pages/UserPage/index';
import VerificationCodePage from './pages/VerificationCodePage/index';
import WifiOff from './assets/icons/wifi-off.svg';
import './App.scss';

const isEnvDev = process.env.NODE_ENV === 'development';
!isEnvDev &&
  Sentry.init({
    dsn: 'https://aca96dd2674741d6abf6e78a3873fcfe@o4503971508453376.ingest.sentry.io/4504016995221504',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });

Modal.setAppElement('#root');

const App: React.FC = () => {
  const location = useLocation();
  const ui = useSelector((state: AppState) => state.ui);
  const {isAuthenticated} = useAuth();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const userRoles = useSelector((state: AppState) => state.auth.roles) || [];
  const [routesEnabled, setRoutesEnabled] = useState<any>([]);

  useEffect(() => {
    localStorage.setItem('no-network-icon', WifiOff);
  }, []);

  useEffect(() => {
    if (isAuthenticated && userRoles?.length) {
      setRoutesEnabled(getAllowedRoutesForUserRole(userRoles));
    }
  }, [userRoles]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(
        loadCommunityData({
          cityName: t(manaGlobalObject.cityName),
          id: manaGlobalObject.id,
          name: t(manaGlobalObject.name),
        }),
      );
      dispatch(loadUserInformation());
      dispatch(getAdminUserRoles());
    }
  }, [isAuthenticated]);

  return (
    <div data-testid="app" className="App">
      {ui.confirmationPopup.isVisible && !ui.confirmationPopup.actionLink && (
        <ConfirmationPopup
          delay={ui.confirmationPopup.delay}
          text={ui.confirmationPopup.message}
          type={ui.confirmationPopup.type}
        />
      )}

      {ui.confirmationPopup.isVisible &&
        location.pathname === EMenuOptions.TaskManager && (
          <ConfirmationPopup
            delay={ui.confirmationPopup.delay}
            text={ui.confirmationPopup.message}
            type={ui.confirmationPopup.type}
            actionLink={ui.confirmationPopup.actionLink}
          />
        )}

      <Routes>
        <Route
          path={pages.root}
          element={
            isAuthenticated ? <Navigate to={routesEnabled[0]} /> : <LoginPage />
          }
        />

        {!isAuthenticated && (
          <Route
            path={EMenuOptions.ForgotPassword}
            element={<ForgotPasswordPage />}
          />
        )}

        {!isAuthenticated && (
          <Route
            path={EMenuOptions.VerificationCode}
            element={<VerificationCodePage />}
          />
        )}

        {!isAuthenticated && (
          <Route
            path={EMenuOptions.NewPassword}
            element={<NewPasswordPage />}
          />
        )}

        {routesEnabled.includes(EMenuOptions.Dashboard) && (
          <Route
            path={EMenuOptions.Dashboard}
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
        )}

        {routesEnabled.includes(EMenuOptions.TaskManager) && (
          <Route
            path={EMenuOptions.TaskManager}
            element={
              <RequireAuth>
                <TaskManagerPage />
              </RequireAuth>
            }
          />
        )}

        {routesEnabled.includes(EMenuOptions.Reports) && (
          <Route
            path={EMenuOptions.Reports}
            element={
              <RequireAuth>
                <ReportingPage />
              </RequireAuth>
            }
          />
        )}

        {routesEnabled.includes(EMenuOptions.Tiers) && (
          <Route
            path={EMenuOptions.Tiers}
            element={
              <RequireAuth>
                <TierPage />
              </RequireAuth>
            }
          />
        )}

        {routesEnabled.includes(EMenuOptions.ManaNews) && (
          <Route
            path={EMenuOptions.ManaNews}
            element={
              <RequireAuth>
                <NewsPage />
              </RequireAuth>
            }
          />
        )}

        {routesEnabled.includes(EMenuOptions.Events) && (
          <Route
            path={EMenuOptions.Events}
            element={
              <RequireAuth>
                <EventsPage />
              </RequireAuth>
            }
          />
        )}

        {routesEnabled.includes(EMenuOptions.Users) && (
          <Route
            path={EMenuOptions.Users}
            element={
              <RequireAuth>
                <UserPage />
              </RequireAuth>
            }
          />
        )}

        <Route path="*" element={<Navigate to={routesEnabled[0]} />} />
      </Routes>
    </div>
  );
};

export default isEnvDev ? App : Sentry.withProfiler(App);
