import {IEvent} from '../../models/api/event';
import {EventOrderBy} from '../../models/enums/eventOrderBy';

export const SET_EVENT_LIST = 'SET_EVENT_LIST';
export const SET_EVENT_LIST_SORTING = 'SET_EVENT_LIST_SORTING';
export const CLEAR_EVENT_LIST_PARAMS = 'CLEAR_EVENT_LIST_PARAMS';
export const CLEAR_EVENT_STATE = 'CLEAR_EVENT_STATE';
export const SET_EVENT_LIST_IS_LOADING = 'SET_EVENT_LIST_IS_LOADING';
export const SET_EVENT_CURRENT_PAGE = 'SET_EVENT_CURRENT_PAGE';
export const SET_EVENT_COMMUNITY = 'SET_EVENT_COMMUNITY';

interface ISetEventList {
  type: typeof SET_EVENT_LIST;
  payload: IEvent[];
}

interface ISetEventListIsLoading {
  type: typeof SET_EVENT_LIST_IS_LOADING;
  payload: boolean;
}

interface ISetEventLitSorting {
  type: typeof SET_EVENT_LIST_SORTING;
  payload: EventOrderBy;
}

interface IClearEventListParams {
  type: typeof CLEAR_EVENT_LIST_PARAMS;
}

interface IClearEventState {
  type: typeof CLEAR_EVENT_STATE;
}

interface ISetEventCurrentPage {
  type: typeof SET_EVENT_CURRENT_PAGE;
  payload: number;
}

interface IClearEventState {
  type: typeof CLEAR_EVENT_STATE;
}

interface ISetEventCommunity {
  type: typeof SET_EVENT_COMMUNITY;
  payload: IEvent;
}

export type EventActionTypes =
  | ISetEventList
  | ISetEventListIsLoading
  | ISetEventLitSorting
  | ISetEventCommunity
  | IClearEventListParams
  | IClearEventState
  | ISetEventCurrentPage
  | IClearEventState;
