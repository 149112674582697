import React from 'react';
import './styles.scss';

interface ITextAreaFieldProps {
  rows?: number;
  cols?: number;
  required?: boolean;
  wrap?: string;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  name?: string;
  readOnly?: boolean;
  label?: number;
  onChange: (e: string) => void;
  value: string;
}

const TextAreaField: React.FC<ITextAreaFieldProps> = ({
  rows = 3,
  cols = 3,
  required = false,
  wrap = '',
  disabled = false,
  placeholder = '',
  maxLength = 200,
  name = '',
  readOnly = false,
  label = '',
  onChange,
  value,
}) => {
  return (
    <div className="text-area-field">
      {label && <span className="label">{label} </span>}
      <textarea
        className="text-area-field-input"
        value={value}
        onChange={e => onChange(e.target.value)}
        rows={rows}
        cols={cols}
        required={required}
        wrap={wrap}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        name={name}
        readOnly={readOnly}
      />
    </div>
  );
};

export default TextAreaField;
