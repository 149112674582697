import React, {useState} from 'react';
import EyeEnabled from '../../assets/icons/eye-enabled.svg';
import EyeDisabled from '../../assets/icons/eye-disabled.svg';
import CSS from 'csstype';
import './styles.scss';

export interface ITextFieldCustomStyles {
  container?: CSS.Properties;
}

interface IProps {
  customStyle?: ITextFieldCustomStyles;
  error?: boolean;
  handleBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  label?: string;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  placeHolder?: string;
  readOnly?: boolean;
  required?: boolean;
  showLabel?: boolean;
  type: 'email' | 'text' | 'password';
  value: string;
}

export const TextField = ({
  customStyle,
  error,
  handleBlur,
  handleChange,
  id,
  label,
  maxLength,
  minLength,
  placeHolder,
  readOnly = false,
  required,
  showLabel = true,
  type,
  value,
}: IProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const onInputChange = (e: any) => {
    e.preventDefault();
    handleChange(e);
    if (!isFocused && e.target?.value?.length === 0) {
      setIsFocused(false);
    }
  };

  return (
    <div
      data-testid={'text-field'}
      className={'text-field'}
      style={customStyle?.container || {}}>
      {showLabel && label && (
        <label className={'field-label'} htmlFor={id}>
          {label}
        </label>
      )}
      {type !== 'password' ? (
        <input
          id={id}
          name={id}
          type={type}
          className={`field-input ${error ? 'error' : ''} ${
            readOnly ? 'disabled' : ''
          }`}
          data-testid={'login-page-user-input'}
          value={value || ''}
          onChange={onInputChange}
          placeholder={placeHolder}
          maxLength={maxLength}
          minLength={minLength}
          readOnly={readOnly}
          required={required}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
        />
      ) : (
        <div className="password-wrapper">
          <input
            id={id}
            name={id}
            type={showPassword ? 'text' : type}
            className={`field-input ${error ? 'error' : ''}`}
            data-testid={'login-page-user-input'}
            value={value || ''}
            onChange={onInputChange}
            placeholder={placeHolder}
            maxLength={maxLength}
            minLength={minLength}
            required={required}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
          />
          {value.length > 0 && (
            <img
              className="password-eye"
              src={showPassword ? EyeDisabled : EyeEnabled}
              alt="eye"
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </div>
      )}
    </div>
  );
};
