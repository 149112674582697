import {IEventState} from '../../models/interfaces/taskState';
import {
  CLEAR_EVENT_LIST_PARAMS,
  CLEAR_EVENT_STATE,
  SET_EVENT_LIST_SORTING,
  SET_EVENT_LIST_IS_LOADING,
  SET_EVENT_LIST,
  EventActionTypes,
  SET_EVENT_CURRENT_PAGE,
  SET_EVENT_COMMUNITY,
} from '../types/eventListTypes';
import {EventOrderBy} from '../../models/enums/eventOrderBy';

const initialState: IEventState = {
  results: [],
  pageCount: 1,
  pageSize: 10,
  currentPage: 0,
  rowCount: 0,
  sortBy: EventOrderBy.Newest,
  isLoading: false,
};

const eventReducer = (
  state = initialState,
  action: EventActionTypes,
): IEventState => {
  switch (action.type) {
    case SET_EVENT_LIST:
      return {...state, ...action.payload};
    case SET_EVENT_LIST_SORTING:
      return {...state, sortBy: action.payload};
    case CLEAR_EVENT_LIST_PARAMS:
      return {
        ...state,
        sortBy: initialState.sortBy,
      };
    case CLEAR_EVENT_STATE:
      return initialState;
    case SET_EVENT_LIST_IS_LOADING:
      return {...state, isLoading: action.payload};
    case SET_EVENT_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_EVENT_COMMUNITY: {
      const results = state.results.map(e => {
        if (e.id === action.payload.id) {
          return {
            ...e,
            communityId: action.payload.communityId,
          };
        } else {
          return e;
        }
      });
      return {
        ...state,
        results,
      };
    }
    default:
      return state;
  }
};

export default eventReducer;
